import React, { useContext, useEffect, useState, useRef } from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Menu from "../../components/Menu";
import "./_Resources.scss";
import { Tooltip } from "@carbon/react";
import { Launch, Information } from "@carbon/icons-react";
import InfoContext from "../../components/InfoContext";
import GlobalURLContext from "../../components/GlobalURLContext";
import BastaImage from "../../images/Basta.jpg";
import YearUpImage from "../../images/Year Up.png";
import ForageImage from "../../images/Forage.png";
import IBMImage from "../../images/IBM-logo.png";
import CoopImage from "../../images/COOP.png";
import SkillfullyImage from "../../images/Skillfully-c.jpg";
import OneTenImage from "../../images/OneTen.png";
import TooltipMUI from "@mui/material/Tooltip";
import Logo from "../../images/logo-new.png";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
//import ResourceImage from "../../images/resources_and_programs.jpg";
//import ResourceImageCompressed from "../../images/resources_and_programs2.jpg";
import ResourceImage from "../../images/Desktop+Tablet/Section Graphics/desktop_resources.jpg";
import ResourceImageCompressed from "../../images/Desktop+Tablet/Section Graphics/desktop_resources_compressed.jpeg";
import ResourceImageMobile from "../../images/Mobile/Resources+Opportunities.jpg";
import ResourceImageCompressedMobile from "../../images/Mobile/Resources+Opportunities_compressed.jpeg";
import ProgressiveImage from "../../components/ProgressiveImage";
import { registerClick } from "../../Helpers";

function Resources({ setLoggedIn }) {
    const infoContext = useContext(InfoContext);
    const globalURLContext = useContext(GlobalURLContext);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const resources = [
        {
            id: "skillfully-tile",
            name: "Skillfully",
            link: "https://www.skillful.ly/",
            description: (
                <div>
                    Skillfully is an online tool that matches you to employment
                    opportunities based on your{" "}
                    <span style={{ fontFamily: "Trim-SemiBold" }}>
                        demonstrated skills
                    </span>{" "}
                    and unique{" "}
                    <span style={{ fontFamily: "Trim-SemiBold" }}>behavioral match</span>{" "}
                    to the job, measured by objective data and backed by science. They’ll
                    help you to{" "}
                    <span style={{ fontFamily: "Trim-SemiBold" }}>
                        develop the skills
                    </span>{" "}
                    you need, then use those skills to land the perfect job for you.
                </div>
            ),
            hide: false,
            image: SkillfullyImage,
        },
        {
            id: "oneten-tile",
            name: "OneTen",
            link: "https://oneten.org/",
            description: (
                <div>
                    OneTen connects{" "}
                    <span style={{ fontFamily: "Trim-SemiBold" }}>Black talent</span> to{" "}
                    <span style={{ fontFamily: "Trim-SemiBold" }}>
                        well-paying job opportunities
                    </span>{" "}
                    through their Career Marketplace—no four-year degree required.
                </div>
            ),
            hide:
                infoContext.info?.race !== "Black or African American" &&
                infoContext.info?.race !== "Two or more races",
            image: OneTenImage,
        },
        {
            id: "forage-tile",
            name: "Forage",
            link: "https://www.theforage.com/",
            description: (
                <div>
                    Forage is a{" "}
                    <span style={{ fontFamily: "Trim-SemiBold" }}>
                        virtual work experience
                    </span>{" "}
                    program that{" "}
                    <span style={{ fontFamily: "Trim-SemiBold" }}>
                        replicates work at top companies
                    </span>{" "}
                    and helps you to gain the skills to be successful when applying and
                    working there. In only 5-6 hours you’ll learn the relevant tools
                    necessary to complete a typical workday at that company.
                </div>
            ),
            hide: false,
            image: ForageImage,
        },
        {
            id: "skillsbuild-tile",
            name: "IBM Skillsbuild",
            link: "https://skillsbuild.org/learners",
            description: (
                <div>
                    Explore new technologies, build skills that are foundational for the
                    workplace, and{" "}
                    <span style={{ fontFamily: "Trim-SemiBold" }}>
                        earn digital credentials
                    </span>{" "}
                    to show what you’ve learned—all for free. Access{" "}
                    <span style={{ fontFamily: "Trim-SemiBold" }}>
                        software and hands-on resources
                    </span>{" "}
                    to learn about{" "}
                    <span style={{ fontFamily: "Trim-SemiBold" }}>
                        data science, artificial intelligence, cybersecurity
                    </span>{" "}
                    and more.
                </div>
            ),
            hide: false,
            image: IBMImage,
            width: "90px",
        },
    ].filter((r) => !r.hide);

    const programs = [
        {
            id: "basta-tile",
            name: "BASTA",
            link: "https://www.projectbasta.com/",
            description: (
                <div>
                    The Basta Fellowship is a{" "}
                    <span style={{ fontFamily: "Trim-SemiBold" }}>
                        10-week career-prep fellowship program
                    </span>{" "}
                    for{" "}
                    <span style={{ fontFamily: "Trim-SemiBold" }}>
                        first-generation college students
                    </span>{" "}
                    and recent graduates to land their first jobs. Through Basta’s{" "}
                    <span style={{ fontFamily: "Trim-SemiBold" }}>
                        weekly sessions and on-demand 1:1 coaching
                    </span>
                    , a full-time position with benefits and a competitive salary is
                    within reach for all.
                </div>
            ),
            hide: false,
            image: BastaImage,
        },
        {
            id: "coop-tile",
            name: "COOP Careers",
            link: "https://coopcareers.org/",
            description: (
                <div>
                    COOP’s fellowship program{" "}
                    <span style={{ fontFamily: "Trim-SemiBold" }}>
                        matches peer cohorts
                    </span>{" "}
                    of motivated but underemployed college graduates with{" "}
                    alumni coaches to
                    support them in{" "}
                    <span style={{ fontFamily: "Trim-SemiBold" }}>
                        building the technical and professional skills and networks
                    </span>{" "}
                    they need to get their first high-paying job after graduation. For 16
                    weeks, fellows learn technical skills, meet potential employers, and
                    build community to secure full-time positions in Business Development,
                    Digital Marketing, Data Analytics, and Financial Services.
                </div>
            ),
            hide: false,
            image: CoopImage,
            width: "115px",
        },
        {
            id: "yearup-tile",
            name: "Year Up",
            link: "https://www.yearup.org/",
            description: (
                <div>
                    Year Up is a{" "}
                    <span style={{ fontFamily: "Trim-SemiBold" }}>
                        3-step job training program
                    </span>{" "}
                    for students that takes 1 year or less to complete. Year Up offers{" "}
                    <span style={{ fontFamily: "Trim-SemiBold" }}>
                        immersive job training and hands-on work experience
                    </span>{" "}
                    to build your resume, your network, and start your career in IT, cyber
                    security, customer service, data analytics, or another chosen
                    industry.
                </div>
            ),
            hide: false,
            image: YearUpImage,
        },
    ].filter((r) => !r.hide);

    const [cResources, setCResources] = useState(resources);
    const [cPrograms, setCPrograms] = useState(programs);

    const [linkUnderline, setLinkUnderline] = useState({});

    function handleClick(link) {
        var handle = window.open(link);
        handle.blur();
        window.focus();
    }

    async function registerRClick(id) {
        return registerClick(id, undefined, setLoggedIn);
    }

    function handleDownloadClick(file) {
        //setIsLoading(true)
        const controller = new AbortController();
        const timeoutId = setTimeout(() => controller.abort(), 60000);
        let headers = new Headers();
        let token = window.sessionStorage.getItem("accessToken");
        headers.append("Authorization", `Bearer ${token}`);
        let options = {
            headers: headers,
            redirect: "follow",
            signal: controller.signal,
        };
        let url = `${globalURLContext.globalURL}/${file}`;

        fetch(url, options)
            .then((res) => res.blob())
            .then((blob) => {
                const a = document.createElement("a");
                a.download = file === "resume_template" ? "Jobs Council Resume & Cover Letter Template.docx" : "NYJCC_Digital_Resource_2024.pdf";
                a.href = window.URL.createObjectURL(blob);
                const clickEvent = new MouseEvent("click", {
                    view: window,
                    bubbles: true,
                    cancelable: true,
                });
                a.dispatchEvent(clickEvent);
                a.remove();
            });
    }

    return (
        <div className="page">
            <div className="resources">
                {window.innerWidth > 600 ? (
                    <div
                        style={{
                            padding: "12px 2% 0px 2%",
                            minHeight: 120,
                            marginBottom: 20,
                            backgroundColor: "white",
                        }}
                    >
                        <div style={{ maxWidth: "1920px", margin: "auto" }}>
                            <ProgressiveImage
                                src={ResourceImage}
                                placeholderSrc={ResourceImageCompressed}
                                style={{
                                    maxWidth: "100%",
                                    minWidth: "100%",
                                    paddingBottom: "24px",
                                }}
                            />
                        </div>
                    </div>
                ) : (
                    <div
                        style={{
                            padding: "32px 5% 0px 5%",
                            minHeight: 120,
                            marginBottom: 20,
                            backgroundColor: "white",
                        }}
                    >
                        <div style={{ maxWidth: "1520px", margin: "auto" }}>
                            <ProgressiveImage
                                src={ResourceImageMobile}
                                placeholderSrc={ResourceImageCompressedMobile}
                                style={{
                                    maxWidth: "100%",
                                    minWidth: "100%",
                                    paddingBottom: "24px",
                                }}
                            />
                        </div>
                    </div>
                )}
                <div
                    className="resource-stacks"
                    style={{ padding: "0px 2% 0px 2%", maxWidth: "1520px" }}
                >
                    <div className="resource-class">
                        <div className="resource-class-header">
                            <Tooltip
                                align="top"
                                label="The NYJCC is partnering with organizations to provide free access to skill building resources. No application required."
                                tabIndex={0}
                            >
                                <button
                                    className="tooltip-trigger"
                                    style={{ color: "white" }}
                                    type="button"
                                >
                                    <span
                                        style={{
                                            color: "var(--bs-secondary)",
                                            fontFamily: "Trim-SemiBold",
                                        }}
                                    >
                                        RESOURCES
                                    </span>
                                </button>
                            </Tooltip>
                        </div>
                        {cResources?.map((r) => (
                            <>
                                <div
                                    id={r.id}
                                    className="resource"
                                    onMouseLeave={() =>
                                        setLinkUnderline((linkUnderline) => ({
                                            ...linkUnderline,
                                            [r.name]: false,
                                        }))
                                    }
                                    onMouseOver={() =>
                                        setLinkUnderline((linkUnderline) => ({
                                            ...linkUnderline,
                                            [r.name]: true,
                                        }))
                                    }
                                    onClick={(e) => {
                                        handleClick(r.link);
                                        registerRClick(r.id);
                                    }}
                                >
                                    <h4 className="resource-name">
                                        <div style={{ display: "flex" }}>
                                            <img src={r.image} width={r.width || "130px"} />
                                            {r.name === "IBM Skillsbuild" && (
                                                <div
                                                    className="plex"
                                                    style={{
                                                        marginLeft: 14,
                                                        minWidth: 150,
                                                        alignSelf: "center",
                                                        color: "rgb(91,89,89,0.8)",
                                                        fontSize: "20px",
                                                    }}
                                                >
                                                    SkillsBuild
                                                </div>
                                            )}
                                        </div>
                                    </h4>
                                    <div children={r.description}></div>
                                    <div
                                        className="resource-link"
                                        style={{
                                            textDecoration: linkUnderline[r.name]
                                                ? "underline"
                                                : "none",
                                        }}
                                    >
                                        Enroll now
                                        <ArrowForwardIcon
                                            style={{
                                                marginLeft: 8,
                                                position: "relative",
                                                left: linkUnderline[r.name] ? 2 : 0,
                                                transition: "left ease-out 0.25s",
                                                transitionDelay: "130ms",
                                            }}
                                        />
                                    </div>
                                </div>
                            </>
                        ))}
                        <div
                            className="resource"
                            onMouseLeave={() =>
                                setLinkUnderline((linkUnderline) => ({
                                    ...linkUnderline,
                                    template: false,
                                }))
                            }
                            onMouseOver={() =>
                                setLinkUnderline((linkUnderline) => ({
                                    ...linkUnderline,
                                    template: true,
                                }))
                            }
                            onClick={() => {
                                handleDownloadClick("resume_template");
                                registerRClick("resume-template-tile");
                            }}
                        >
                            <h4 className="resource-name-template">
                                <div style={{ display: "flex" }}>
                                    <img
                                        width="190px"
                                        src={Logo}
                                        className="header-logo"
                                        alt="New York Jobs CEO Council Logo"
                                    />
                                    <div style={{ marginLeft: 12, minWidth: 150, marginTop: 16 }}>
                                        Resume Template
                                    </div>
                                </div>
                            </h4>
                            <div>
                                Did you know the average recruiter spends <b>6 seconds</b>{" "}
                                looking at a resume? The Jobs Council has created a{" "}
                                <b>resume and cover letter guide</b> to ensure your resume
                                passes recruiter inspection. Be sure to use the template
                                provided and utilize the{" "}
                                <b>tips to get your resume recruiter-ready</b>.
                            </div>
                            <div
                                className="resource-link"
                                style={{
                                    textDecoration: linkUnderline["template"]
                                        ? "underline"
                                        : "none",
                                }}
                            >
                                Download template
                                <ArrowForwardIcon
                                    style={{
                                        marginLeft: 8,
                                        position: "relative",
                                        left: linkUnderline["template"] ? 2 : 0,
                                        transition: "left ease-out 0.25s",
                                        transitionDelay: "130ms",
                                    }}
                                />
                            </div>
                        </div>
                        <div
                            className="resource"
                            onMouseLeave={() =>
                                setLinkUnderline((linkUnderline) => ({
                                    ...linkUnderline,
                                    book: false,
                                }))
                            }
                            onMouseOver={() =>
                                setLinkUnderline((linkUnderline) => ({
                                    ...linkUnderline,
                                    book: true,
                                }))
                            }
                            onClick={() => {
                                handleDownloadClick("job_book");
                                registerRClick("job-book-tile");
                            }}
                        >
                            <h4 className="resource-name-template">
                                <div style={{ display: "flex" }}>
                                    <img
                                        width="190px"
                                        src={Logo}
                                        className="header-logo"
                                        alt="New York Jobs CEO Council Logo"
                                    />
                                    <div style={{ marginLeft: 12, minWidth: 150, marginTop: 16 }}>
                                        Recruitment Guide
                                    </div>
                                </div>
                            </h4>
                            <div>
                                <div style={{ fontFamily: "Trim-SemiBold", marginBottom: -18, lineHeight: 1.4, fontSize: "15.5px" }}>You can make it here: A guide to early recruitment timelines at
                                    Jobs Council Companies</div><br /> The job search can be overwhelming. There
                                are so many companies, with so many different roles—when do they
                                open and how do you apply?! We’re here to help. <br /> Whether you’re a
                                freshman or a senior, use this guide to explore the different
                                early career (internship) opportunities at our member companies.
                            </div>
                            <div
                                className="resource-link"
                                style={{
                                    textDecoration: linkUnderline["book"]
                                        ? "underline"
                                        : "none",
                                }}
                            >
                                Check out the guide
                                <ArrowForwardIcon
                                    style={{
                                        marginLeft: 8,
                                        position: "relative",
                                        left: linkUnderline["book"] ? 2 : 0,
                                        transition: "left ease-out 0.25s",
                                        transitionDelay: "130ms",
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="resource-class">
                        <div className="resource-class-header">
                            <Tooltip
                                align="top"
                                label="The NYJCC is partnering with organizations to provide selected candidates free access to career building programs."
                                tabIndex={0}
                            >
                                <button
                                    className="tooltip-trigger"
                                    style={{
                                        color: "white" /*backgroundColor: "var(--bs-primary)"*/,
                                    }}
                                    type="button"
                                >
                                    <span
                                        style={{
                                            color: "var(--bs-secondary)",
                                            fontFamily: "Trim-SemiBold",
                                        }}
                                    >
                                        PROGRAMS
                                    </span>
                                </button>
                            </Tooltip>
                        </div>

                        {cPrograms?.map((r) => (
                            <>
                                <div
                                    id={r.id}
                                    className="resource"
                                    onMouseLeave={() =>
                                        setLinkUnderline((linkUnderline) => ({
                                            ...linkUnderline,
                                            [r.name]: false,
                                        }))
                                    }
                                    onMouseOver={() =>
                                        setLinkUnderline((linkUnderline) => ({
                                            ...linkUnderline,
                                            [r.name]: true,
                                        }))
                                    }
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        console.log(r.id);
                                        console.log(Object.keys(e.target));
                                        handleClick(r.link);
                                        registerRClick(r.id);
                                    }}
                                >
                                    <h4 className="resource-name">
                                        <img src={r.image} width={r.width || "130px"} />
                                    </h4>
                                    {r.description}
                                    <div
                                        className="resource-link"
                                        style={{
                                            textDecoration: linkUnderline[r.name]
                                                ? "underline"
                                                : "none",
                                        }}
                                    >
                                        Apply now
                                        <ArrowForwardIcon
                                            style={{
                                                marginLeft: 8,
                                                position: "relative",
                                                left: linkUnderline[r.name] ? 2 : 0,
                                                transition: "left ease-out 0.25s",
                                                transitionDelay: "130ms",
                                            }}
                                        />
                                    </div>
                                </div>
                            </>
                        ))}
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default Resources;
