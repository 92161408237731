import React, { useState, useEffect, useContext, useCallback } from "react";
import "../../index.scss";
import "./_ProfileComponent.scss";
import {
    Dropdown,
    MultiSelect,
    Button,
    TextInput,
    DatePicker,
    DatePickerInput,
    ComboBox,
    SkeletonPlaceholder,
} from "@carbon/react";
import { Snackbar, Alert } from "@mui/material";
import { _4K } from "@carbon/icons-react";
import SaveIcon from "@mui/icons-material/Save";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowLeft from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import InfoContext from "../../components/InfoContext";
//import JobRecommendationsContext from "../../components/JobRecommendationsContext";
//import SkillsContext from "../../components/SkillsContext";
import GlobalURLContext from "../../components/GlobalURLContext";
//import SoftSkillsContext from "../../components/SoftSkillsContext";
import EventContext from "../../components/EventContext";
import OpenJobsContext from "../../components/OpenJobsContext";
import { validateInput, shuffleArray } from "../../Helpers";
//import states from "./states.json";
import { registerClick } from "../../Helpers";

const company = [
    "Accenture",
    "AIG",
    "Amazon",
    "American Express",
    "Aon",
    "Bank of America",
    "BlackRock",
    "Bloomberg",
    "BNY Mellon",
    "Citi",
    "City of New York",
    "Con Edison",
    "Deloitte",
    "EY",
    "Goldman Sachs",
    "Google",
    "IBM",
    "J.P. Morgan",
    "KPMG",
    "Mastercard",
    "McKinsey & Co.",
    "Memorial Sloan Kettering",
    "Microsoft",
    "Montefiore",
    "Mount Sinai",
    "New York Presbyterian",
    "Northwell Health",
    "PwC",
    "Ralph Lauren",
    "State of New York",
    "TD Bank",
    "The New York Times",
    "Tishman Speyer",
    "Travelers",
    "Verizon",
    "Wells Fargo",
    "Other (please specify)",
];
const salary = [
    "$0-$30,000",
    "$31,000-$57,000",
    "$58,000-$70,000",
    "$71,000-$97,000",
    "$98,000-$120,000",
    "$120,000+",
];
const wage = [
    "$15-$20 / hour",
    "$20-$25 / hour",
    "$25-$30 / hour",
    "More than $30 / hour",
];
const employmentStatus = [
    "I am a full-time employee",
    "I am a part-time employee",
    "I am looking for full-time employment",
    "I am a full-time student",
    "I am an intern",
    "I am an apprentice",
    "I am  a contractor",
    "I am self-employed",
];
const source = [
    "Career Services",
    "LinkedIn",
    "New York Jobs CEO Council / EverUp",
    "Online search engine (Jobs.com, Indeed, etc)",
    "Professor or Faculty",
    "Other (please specify)",
];
const studentStatus = ["Part-time", "Full-time", "Non-degree seeking"];

const gender = ["Female", "Male", "Non-Binary", "Other"];
const race = [
    "Asian",
    "Black or African American",
    "Hispanic or Latinx",
    "White",
    "Native American or Alaskan Native",
    "Native Hawaiian or Pacific Islander",
    "Two or more races",
];
const cunyCollege = [
    "Baruch College",
    "Borough of Manhattan Community College",
    "Bronx Community College",
    "Brooklyn College",
    "College of Staten Island",
    "Craig Newmark Graduate School of Journalism at CUNY",
    "CUNY Graduate Center",
    "CUNY Graduate School of Public Health and Health Policy",
    "CUNY School of Labor and Urban Studies",
    "CUNY School of Law",
    "CUNY School of Professional Studies",
    "Guttman Community College",
    "Hostos Community College",
    "Hunter College",
    "John Jay College of Criminal Justice",
    "Kingsborough Community College",
    "LaGuardia Community College",
    "Lehman College",
    "Medgar Evers College",
    "New York City College of Technology",
    "Queens College",
    "Queensborough Community College",
    "The City College of New York",
    "York College",
    "Graduated",
    "Never enrolled/Did not graduate",
    "Other (please specify)",
];
const degree = [
    "Associate Degree",
    "Bachelor Degree",
    "Graduate/Advanced Degree",
    "Other",
];
const credits = ["0-29", "30-59", "60-89", "90-119", "120+"];
const gpa = [
    "less than 1.99",
    "2.00 - 2.49",
    "2.50 - 2.99",
    "3.00 - 3.49",
    "3.50 - 3.99",
    "4.00",
];
const major = [
    "AC Equipment Technician",
    "Accounting",
    "Actuarial Mathematics",
    "Actuarial Science",
    "Actuarial Studies",
    "Adolescence Education",
    "Adult Health",
    "Adult Nursing Practitioner",
    "Advertising",
    "African Studies",
    "Alchohol and Substance Abuse",
    "Animation and Motion Graphics",
    "Anthropology",
    "Archaeology",
    "Architecture",
    "Art",
    "Automotive Technology",
    "Aviation Management",
    "Bilingual Childhood Education",
    "Biochemistry",
    "Biological Sciences",
    "Business Administration",
    "Chemistry",
    "Civil Engineering",
    "Communication",
    "Computer Information Systems",
    "Computer Network Technology",
    "Computer Science",
    "Computer Systems/Computer Technology",
    "Construction Management",
    "Criminal Justice",
    "Cybersecurity and Networking",
    "Data Science",
    "Early Childhood Education",
    "Earth Science",
    "Economics",
    "Education",
    "English",
    "Environmental Health",
    "Film and Media Production",
    "Finance",
    "Geography",
    "Health and Nutrition Sciences",
    "History",
    "Information Systems",
    "Journalism and Media Studies",
    "Legal Studies",
    "Liberal Arts",
    "Life Sciences",
    "Marketing",
    "Mathematics",
    "Media Arts",
    "Media Communication Studies",
    "Music",
    "Nursing",
    "Office Administration and Technology",
    "Paralegal Studies",
    "Philosophy",
    "Physics",
    "Political Science",
    "Psychology",
    "Other",
];
const eduYear = [
    "Freshman",
    "Sophomore",
    "Junior",
    "Senior",
    "Graduated",
    "Taking a break from education",
];
const eventInterest = [
    "Networking with peers",
    "Networking with potential employers",
    "Competitions relevant to your field (ie: Hackathon)",
    "Professional development workshops (ie: LinkedIn training)",
    "All events types",
    "Other (please specify)",
];

const heardAbout = [
    "Jobs Council CEO Speaker Series event",
    "Futures in Finance",
    "Basta",
    "COOP",
    "Skillful.ly",
    "Forage",
    "PENCIL",
    "Jobs Council Student Ambassador",
    "LevelAll",
    "TikTok",
    "Instagram",
    "LinkedIn",
    "CUNY faculty or staff member",
    "Other",
];

function ProfileComponent({ isCheckIn, setLoggedIn }) {
    const navigate = useNavigate();

    const globalURLContext = useContext(GlobalURLContext);
    //const jobRecommendationsContext = useContext(JobRecommendationsContext);
    const openJobsContext = useContext(OpenJobsContext);
    //const skillsContext = useContext(SkillsContext);
    //const softSkillsContext = useContext(SoftSkillsContext);
    const eventContext = useContext(EventContext);
    //const resumeContext = useContext(ResumeContext);
    const infoContext = useContext(InfoContext);

    const [otherCompany, setOtherCompany] = useState(
        infoContext.info?.otherCompany
    );
    const [otherSource, setOtherSource] = useState(infoContext.info?.otherSource);
    const [otherEventInterest, setOtherEventInterest] = useState(
        infoContext.info?.otherEventInterest
    );
    const [otherCunyCollege, setOtherCunyCollege] = useState(
        infoContext.info?.otherCunyCollege
    );
    const [phase, setPhase] = useState("employment");
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingEmploy, setIsLoadingEmploy] = useState(false);
    const [isLoadingDemog, setIsLoadingDemog] = useState(false);

    const [tempCompany, setTempCompany] = useState(infoContext.info?.company);
    const [sCompany, setCompany] = useState(infoContext.info?.company);
    const [sSalary, setSalary] = useState(infoContext.info?.salary);
    const [sWage, setWage] = useState(infoContext.info?.wage);
    const [sEmploymentStatus, setEmploymentStatus] = useState(
        infoContext.info?.employmentStatus
    );
    const [sSource, setSource] = useState(infoContext.info?.source);
    const [sStudentStatus, setStudentStatus] = useState(
        infoContext.info?.studentStatus
    );
    const [sResumeUploaded, setResumeUploaded] = useState(
        infoContext.info?.resumeUploaded
    );

    const [sGender, setGender] = useState(infoContext.info?.gender);
    const [sRace, setRace] = useState(infoContext.info?.race);
    const [sCunyCollege, setCunyCollege] = useState(
        infoContext.info?.cunyCollege
    );
    const [sDegree, setDegree] = useState(infoContext.info?.degree);
    const [sCredits, setCredits] = useState(infoContext.info?.credits);
    const [sGPA, setGPA] = useState(infoContext.info?.gpa);
    const [sMajor, setMajor] = useState(infoContext.info?.major);
    const [sGradDate, setGradDate] = useState(infoContext.info?.graduationDate);
    const [sEduYear, setEduYear] = useState(infoContext.info?.eduYear);
    const [sEventInterest, setEventInterest] = useState(
        infoContext.info?.eventInterest
    );
    const [sClubs, setClubs] = useState(infoContext.info?.clubs);
    const [sPhone, setPhone] = useState(infoContext.info?.phone);
    const [sLinkedIn, setLinkedIn] = useState(infoContext.info?.linkedIn);
    const [sZip, setZip] = useState(infoContext.info?.zip);
    const [sHeardAbout, setHeardAbout] = useState(infoContext.info?.heardAbout);

    const [invalidCompany, setInvalidCompany] = useState(false);
    const [invalidSalary, setInvalidSalary] = useState(false);
    const [invalidSource, setInvalidSource] = useState(false);
    const [invalidStudentStatus, setInvalidStudentStatus] = useState(false);
    const [invalidEmploymentStatus, setInvalidEmploymentStatus] = useState(false);
    const [invalidWage, setInvalidWage] = useState(false);
    const [invalidOtherCompany, setInvalidOtherCompany] = useState(false);
    const [invalidOtherSource, setInvalidOtherSource] = useState(false);
    const [invalidCunyCollege, setInvalidCunyCollege] = useState(false);
    const [invalidOtherCunyCollege, setInvalidOtherCunyCollege] = useState(false);
    const [invalidRace, setInvalidRace] = useState(false);
    const [invalidDegree, setInvalidDegree] = useState(false);

    const [invalidZip, setInvalidZip] = useState(false);
    const [invalidGender, setInvalidGender] = useState(false);
    const [invalidEventInterest, setInvalidEventInterest] = useState(false);
    const [invalidPhone, setInvalidPhone] = useState(false);
    const [invalidLinkedIn, setInvalidLinkedIn] = useState(false);
    const [invalidClubs, setInvalidClubs] = useState(false);
    const [invalidOtherEventInterest, setInvalidOtherEventInterest] =
        useState(false);
    const [invalidGradDate, setInvalidGradDate] = useState(false);
    const [invalidGPA, setInvalidGPA] = useState(false);
    const [invalidCredits, setInvalidCredits] = useState(false);
    const [invalidMajor, setInvalidMajor] = useState(false);
    const [invalidEducationYear, setInvalidEducationYear] = useState(false);
    const [invalidHeardAbout, setInvalidHeardAbout] = useState(false);
    //const [invalid, setInvalid] = useState(false);
    //const [invalid, setInvalid] = useState(false);

    const [demographicsUpdated, setDemographicsUpdated] = useState(false);
    const [employmentUpdated, setEmploymentUpdated] = useState(false);
    const [resumeUpdated, setResumeUpdated] = useState(false);
    const [resumeUpdateReq, setResumeUpdateReq] = useState(false);

    //const [snackbarOpen, setSnackbarOpen] = useState(false);
    //const [snackbarMessage, setSnackbarMessage] = useState("");

    async function registerRClick(id) {
        return registerClick(id, undefined, setLoggedIn);
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        if (!infoContext.info?.updated) {
            async function getAccountInfo() {
                //setIsLoading(true);
                const controller = new AbortController();
                const timeoutId = setTimeout(() => controller.abort(), 60000);
                let headers = new Headers();
                let token = window.sessionStorage.getItem("accessToken");
                headers.append("Authorization", `Bearer ${token}`);
                let options = {
                    headers: headers,
                    redirect: "follow",
                    signal: controller.signal,
                };
                let url = new URL(`${globalURLContext.globalURL}/userinfo`);

                await fetch(url, options)
                    .then((response) => {
                        if (response.status === 401) {
                            setLoggedIn(false);
                            window.location.reload();
                            window.sessionStorage.clear();
                            throw new Error("Authentication Error");
                        }
                        if (!response.ok)
                            throw new Error(`Unexpected response status ${response.status}`);
                        else return response.json();
                    })
                    .then(({ FIRST_NAME, LAST_NAME, EMAIL1, LAST_LOGIN }) => {
                        infoContext.setInfo((info) => ({
                            ...info,
                            firstName: FIRST_NAME,
                            lastName: LAST_NAME,
                            email: EMAIL1,
                            lastLogin: LAST_LOGIN,
                        }));
                        setIsLoading(false);
                    })
                    .catch((e) => console.log(e));
            }

            async function getInfo() {
                const controller = new AbortController();
                const timeoutId = setTimeout(() => controller.abort(), 60000);
                setIsLoading(true);
                let headers = new Headers();
                let token = window.sessionStorage.getItem("accessToken");
                headers.append("Authorization", `Bearer ${token}`);
                let options = {
                    headers: headers,
                    redirect: "follow",
                    signal: controller.signal,
                };
                let url = `${globalURLContext.globalURL}/employment_demographics`;

                await fetch(url, options)
                    .then((response) => {
                        if (response.status === 401) {
                            setLoggedIn(false);
                            window.location.reload();
                            window.sessionStorage.clear();
                            throw new Error("Authentication Error");
                        }
                        if (!response.ok)
                            throw new Error(`Unexpected response status ${response.status}`);
                        else return response.json();
                    })
                    .then(
                        ({
                            COMPANY,
                            EMPLOYMENT_TYPE,
                            HOURLY_WAGE,
                            SOURCE,
                            STUDENT_STATUS,
                            SALARY,
                            CLUBS,
                            CREDITS,
                            CREATED,
                            CUNY_COLLEGE,
                            DEGREE_TYPE,
                            EDUCATION_YEAR,
                            EVENT_INTEREST,
                            GENDER,
                            GPA,
                            GRADUATION_DATE,
                            LINKEDIN_URL,
                            MAJOR,
                            //OTHER_SCHOOLS,
                            PHONE,
                            RACE,
                            ZIPCODE,
                            HEARD_ABOUT,
                            RESUME_UPLOADED,
                            //LAST_UPDATED
                        }) => {
                            setSalary(SALARY);
                            setWage(HOURLY_WAGE);
                            setEmploymentStatus(EMPLOYMENT_TYPE);
                            setStudentStatus(STUDENT_STATUS);
                            setResumeUploaded(RESUME_UPLOADED || false);
                            setClubs(CLUBS);
                            setCredits(CREDITS);
                            setCunyCollege(CUNY_COLLEGE);
                            setDegree(DEGREE_TYPE);
                            setEduYear(EDUCATION_YEAR);
                            setGender(GENDER);
                            setGPA(GPA);
                            setGradDate(GRADUATION_DATE);
                            setLinkedIn(LINKEDIN_URL);
                            setMajor(MAJOR);
                            //setOtherCunyCollege(OTHER_SCHOOLS?.length > 0 ? OTHER_SCHOOLS?.split(",") : []);
                            setPhone(PHONE);
                            setRace(RACE);
                            setHeardAbout(HEARD_ABOUT);
                            setZip(ZIPCODE);

                            let eInterests = EVENT_INTEREST?.split(",") || [];
                            let updatedEventInterest = [];
                            let otherEInterest = "";
                            for (let ev of eInterests) {
                                if (![undefined, "", ...eventInterest].includes(ev)) {
                                    otherEInterest = ev;
                                    updatedEventInterest = [
                                        ...updatedEventInterest,
                                        "Other (please specify)",
                                    ];
                                    setOtherEventInterest(ev);
                                } else if (ev !== "") {
                                    updatedEventInterest = [...updatedEventInterest, ev];
                                }
                            }
                            setEventInterest([...updatedEventInterest]);

                            if (![undefined, "", ...source].includes(SOURCE)) {
                                setSource("Other (please specify)");
                                setOtherSource(SOURCE);
                            } else {
                                setSource(SOURCE);
                            }

                            if (![undefined, "", ...company].includes(COMPANY)) {
                                setCompany("Other (please specify)");
                                setTempCompany("Other (please specify)");
                                setOtherCompany(COMPANY);
                            } else {
                                setCompany(COMPANY);
                            }

                            if (![undefined, "", ...cunyCollege].includes(CUNY_COLLEGE)) {
                                setCunyCollege("Other (please specify)");
                                console.log(CUNY_COLLEGE);
                                setOtherCunyCollege(CUNY_COLLEGE);
                            } else {
                                setCunyCollege(CUNY_COLLEGE);
                            }

                            //setIsLoading(false);

                            infoContext.setInfo((info) => ({
                                ...info,
                                company: [undefined, "", ...company].includes(COMPANY)
                                    ? COMPANY
                                    : "Other (please specify)",
                                otherCompany: [undefined, "", ...company].includes(COMPANY)
                                    ? ""
                                    : COMPANY,
                                employmentStatus: EMPLOYMENT_TYPE,
                                wage: HOURLY_WAGE,
                                salary: SALARY,
                                source: [undefined, "", ...source].includes(SOURCE)
                                    ? SOURCE
                                    : "Other (please specify)",
                                otherSource: [undefined, "", ...source].includes(SOURCE)
                                    ? ""
                                    : SOURCE,
                                studentStatus: STUDENT_STATUS,
                                clubs: CLUBS,
                                credits: CREDITS,
                                cunyCollege: [undefined, "", ...cunyCollege].includes(
                                    CUNY_COLLEGE
                                )
                                    ? CUNY_COLLEGE
                                    : "Other (please specify)",
                                otherCunyCollege: [undefined, "", ...cunyCollege].includes(
                                    CUNY_COLLEGE
                                )
                                    ? ""
                                    : CUNY_COLLEGE, //OTHER_SCHOOLS?.split(","),
                                degree: DEGREE_TYPE,
                                eduYear: EDUCATION_YEAR,
                                eventInterest: updatedEventInterest,
                                otherEventInterest: otherEInterest,
                                gender: GENDER,
                                gpa: GPA,
                                graduationDate: GRADUATION_DATE,
                                linkedIn: LINKEDIN_URL,
                                major: MAJOR,
                                phone: PHONE,
                                race: RACE,
                                zip: ZIPCODE,
                                heardAbout: HEARD_ABOUT,
                                updated: true,
                                //lastUpdated: LAST_UPDATED,
                                created: CREATED,
                                resumeUploaded: RESUME_UPLOADED || false,
                            }));
                        }
                    )
                    .catch((e) => {
                        setIsLoading(false);
                    });
            }

            //Promise.allSettled([getAccountInfo(), getInfo()])
            getInfo()
                .then(() => getAccountInfo())
                .then(() => {
                    setIsLoading(false);
                    postLogin();//await to store sessionID more quickly? 
                    //getAccountInfo();
                    getOpenJobs();
                    getEvents();
                    //getJobRecommendations();
                });
        }
    }, []);

    function getOpenJobs() {
        const controller = new AbortController();
        const timeoutId = setTimeout(() => controller.abort(), 60000);
        let token = window.sessionStorage.getItem("accessToken");

        let headers = new Headers();
        headers.append("Accept", "application/json");
        headers.append("Authorization", `Bearer ${token}`);

        let options = {
            method: "GET",
            redirect: "follow",
            headers: headers,
            signal: controller.signal,
        };
        let url = new URL(`${globalURLContext.globalURL}/openjobs`);
        fetch(url, options)
            .then((response) => response.json())
            .then((result) => {
                let newJobs = result
                    .filter(
                        (r) =>
                            (new Date(infoContext.info?.lastLogin) < new Date(r.ADDED) &&
                                (new Date() - new Date(r.ADDED)) / (1000 * 60 * 60 * 24) < 7) ||
                            (new Date() - new Date(r.ADDED)) / (1000 * 60 * 60 * 24) < 4
                    )
                    ?.map((r) => ({ ...r, new: true }));
                let expiringSoon = result
                    .filter(
                        (r) =>
                            !((new Date(infoContext.info?.lastLogin) < new Date(r.ADDED) &&
                                (new Date() - new Date(r.ADDED)) / (1000 * 60 * 60 * 24) < 7) ||
                                (new Date() - new Date(r.ADDED)) / (1000 * 60 * 60 * 24) < 4) &&
                            new Date(r.APPLICATION_DEADLINE) - new Date() < 7
                    )
                    ?.map((r) => ({ ...r, expiring: true }));

                let rest = result
                    .filter(
                        (r) =>
                            !((new Date(infoContext.info?.lastLogin) < new Date(r.ADDED) &&
                                (new Date() - new Date(r.ADDED)) / (1000 * 60 * 60 * 24) < 7) ||
                                (new Date() - new Date(r.ADDED)) / (1000 * 60 * 60 * 24) < 4) &&
                            new Date(r.APPLICATION_DEADLINE) - new Date() >= 7
                    )
                console.log("new " + JSON.stringify(newJobs))
                let jobs = shuffleArray(newJobs)?.concat(shuffleArray(expiringSoon.concat(rest)));
                openJobsContext.setOpenJobs(jobs);
            })
            .catch((e) => {
                console.log(e);
                openJobsContext.setOpenJobs([]);
            });
    }
    /*
          function getJobRecommendations() {
            let token = window.sessionStorage.getItem("accessToken")
          
            let headers = new Headers();
            headers.append("Accept", "application/json");
            headers.append("Authorization", `Bearer ${token}`);
          
            let options = {
                method: "GET",
                redirect: "follow",
                headers: headers
            }
          
            fetch(`${globalURLContext.globalURL}/recommendations`, options)
            .then(response => response.json())
            .then(result => {
                jobRecommendationsContext.setRecos(result);
            })
            .catch(e => {
              console.log(e);
              //resumeContext.setResumeUploaded(false);
              jobRecommendationsContext.setRecos([]);
            });
          }*/

    function getEvents() {
        const controller = new AbortController();
        const timeoutId = setTimeout(() => controller.abort(), 60000);
        let headers = new Headers();
        let token = window.sessionStorage.getItem("accessToken");
        headers.append("Authorization", `Bearer ${token}`);
        let options = {
            headers: headers,
            redirect: "follow",
            signal: controller.signal,
        };
        let url = `${globalURLContext.globalURL}/registeredevents`;

        fetch(url, options)
            .then((response) => {
                if (response.status === 401) {
                    setLoggedIn(false);
                    window.location.reload();
                    window.sessionStorage.clear();
                    throw new Error("Authentication Error");
                }
                if (!response.ok)
                    throw new Error(`Unexpected response status ${response.status}`);
                else return response.json();
            })
            .then((result) => {
                let events = result;
                events = events?.map((e, idx) => ({ ...e, id: e.EVENT_ID }));
                events.sort((e1, e2) => {
                    if (new Date(e1.DATE) > new Date(e2.DATE)) return 1;
                    else if (new Date(e1.DATE) < new Date(e2.DATE)) return -1;
                    return 0;
                });
                eventContext.setEvents(events);
            })
            .catch((e) => {
                console.log(e);
                eventContext.setEvents([]);
            });
    }

    function postLogin() {
        let token = window.sessionStorage.getItem("accessToken");

        let headers = new Headers();
        headers.append("Accept", "application/json");
        headers.append("Content-Type", "application/json");
        headers.append("Authorization", `Bearer ${token}`);

        let options = {
            method: "POST",
            redirect: "follow",
            headers: headers,
        };

        let url = new URL(`${globalURLContext.globalURL}/login`);
        fetch(url, options)
            .then((response) => {
                if (response.status === 401) {
                    setLoggedIn(false);
                    window.location.reload();
                    window.sessionStorage.clear();
                    throw new Error("Authentication Error");
                }

                return response.json();
            })
            .then((result) => {
                window.sessionStorage.setItem("sessionID", result.sessionID)
            })
            .catch((e) => console.log(e));
    }

    useEffect(() => {
        if (
            sEmploymentStatus === "I am looking for full-time employment" ||
            sEmploymentStatus === "I am a full-time student"
        ) {
            setCompany("");
            setTempCompany("");
            setSource("");
            setWage("");
            setSalary("");
            setOtherCompany("");
        } else if (sEmploymentStatus === "I am a full-time employee") setWage("");
        else {
            setSalary("");
        }
    }, [sEmploymentStatus]);

    /*useEffect(() => {
              setTempCompany(sCompany ? sCompany : null);
          }, [sCompany])*/

    /* useEffect(() => {
               if (resumeUpdated) {
                   setResumeUploaded(true);
               }
           }, [resumeUpdated])*/

    //let controller = new AbortController();

    async function postInfo() {
        setIsLoading(true);
        //let updatedEventInterest = sEventInterest;
        //let otherEIndex = updatedEventInterest.indexOf("Other (please specify)");
        //updatedEventInterest.splice(otherEIndex, 1, )

        infoContext.setInfo((info) => ({
            ...info,
            company: sCompany,
            otherCompany: otherCompany,
            employmentStatus: sEmploymentStatus,
            wage: sWage,
            salary: sSalary,
            source: sSource,
            studentStatus: sStudentStatus,
            otherSource: otherSource,
            clubs: sClubs,
            credits: sCredits,
            cunyCollege: sCunyCollege,
            degree: sDegree,
            eduYear: sEduYear,
            eventInterest: sEventInterest,
            otherEventInterest: otherEventInterest,
            gender: sGender,
            gpa: sGPA,
            graduationDate: sGradDate,
            linkedIn: sLinkedIn,
            major: sMajor,
            otherCunyCollege: otherCunyCollege,
            phone: sPhone,
            race: sRace,
            zip: sZip,
            heardAbout: sHeardAbout,
            updated: true,
            created: new Date().toISOString().slice(0, 10),
            resumeUploaded: sResumeUploaded, // || resumeUpdated
        }));

        let token = window.sessionStorage.getItem("accessToken");

        let headers = new Headers();
        headers.append("Authorization", `Bearer ${token}`);
        headers.append("Content-Type", "application/json");

        let updatedEventInterests = sEventInterest ? [...sEventInterest] : [];
        let otherEventIndex = updatedEventInterests?.indexOf(
            "Other (please specify)"
        );
        if (otherEventIndex !== -1 || otherEventIndex === 0)
            updatedEventInterests.splice(otherEventIndex, 1, otherEventInterest);
        let payload = {
            COMPANY: otherCompany || sCompany,
            EMPLOYMENT_TYPE: sEmploymentStatus,
            HOURLY_WAGE: sWage,
            SALARY: sSalary,
            SOURCE: otherSource || sSource,
            STUDENT_STATUS: sStudentStatus,
            CLUBS: sClubs,
            CREDITS: sCredits,
            CUNY_COLLEGE: otherCunyCollege || sCunyCollege,
            DEGREE_TYPE: sDegree,
            EDUCATION_YEAR: sEduYear,
            EVENT_INTEREST: updatedEventInterests?.join(","),
            GENDER: sGender,
            GPA: sGPA,
            GRADUATION_DATE: sGradDate,
            LINKEDIN_URL: sLinkedIn,
            MAJOR: sMajor,
            //OTHER_SCHOOLS: sOtherCunyCollege?.join(","),
            PHONE: sPhone,
            RACE: sRace,
            ZIPCODE: sZip,
            HEARD_ABOUT: sHeardAbout,
            UPDATED_AT: new Date().toISOString().slice(0, 10),
            //LAST_UPDATED: new Date().toLocaleDateString("en-US"),//infoContext.info.lastUpdated,
            CREATED: infoContext.info.created,
            RESUME_UPLOADED: sResumeUploaded || false,
        };
        let options = {
            method: "POST",
            headers: headers,
            redirect: "follow",
            body: JSON.stringify(payload),
        };

        let url = `${globalURLContext.globalURL}/employment_demographics`;

        await fetch(url, options)
            .then((response) => {
                if (response.status === 401) {
                    setLoggedIn(false);
                    window.location.reload();
                    window.sessionStorage.clear();
                    throw new Error("Authentication Error");
                }
                if (!response.ok)
                    throw new Error(`Unexpected response status ${response.status}`);
                setIsLoading(false);
                setResumeUpdateReq(false);
            })
            .catch((e) => console.log(e));
    }

    async function postEmploymentInfo() {
        setIsLoadingEmploy(true);

        infoContext.setInfo((info) => ({
            ...info,
            company: sCompany,
            otherCompany: otherCompany,
            employmentStatus: sEmploymentStatus,
            wage: sWage,
            salary: sSalary,
            source: sSource,
            studentStatus: sStudentStatus,
            otherSource: otherSource,
            updated: true,
            created: new Date().toISOString().slice(0, 10),
            resumeUploaded: sResumeUploaded,
        }));

        let token = window.sessionStorage.getItem("accessToken");
        let headers = new Headers();
        headers.append("Authorization", `Bearer ${token}`);
        headers.append("Content-Type", "application/json");

        let payload = {
            COMPANY: otherCompany || sCompany,
            EMPLOYMENT_TYPE: sEmploymentStatus,
            HOURLY_WAGE: sWage,
            SALARY: sSalary,
            SOURCE: otherSource || sSource,
            STUDENT_STATUS: sStudentStatus,
            UPDATED_AT: new Date().toISOString().slice(0, 10),
            CREATED: infoContext.info.created,
            RESUME_UPLOADED: sResumeUploaded || false
        };
        let options = {
            method: "POST",
            headers: headers,
            redirect: "follow",
            body: JSON.stringify(payload),
        };

        let url = `${globalURLContext.globalURL}/employment`;

        await fetch(url, options)
            .then((response) => {
                if (response.status === 401) {
                    setLoggedIn(false);
                    window.location.reload();
                    window.sessionStorage.clear();
                    throw new Error("Authentication Error");
                }
                if (!response.ok)
                    throw new Error(`Unexpected response status ${response.status}`);
                setIsLoadingEmploy(false);
                setResumeUpdateReq(false);
                /*setSnackbarOpen(true);
                                    setSnackbarMessage("Thank you for updating your info!");
                                    setTimeout(() => setSnackbarOpen(false), 6000)*/
                getEvents();
            })
            .catch((e) => {
                console.log(e);
                /*setSnackbarOpen(true);
                                    setSnackbarMessage("There was a problem with updating your info. Please try again later.");
                                    setTimeout(() => setSnackbarOpen(false), 6000);*/
            });
    }

    async function postDemographicInfo() {
        setIsLoadingDemog(true);
        //let updatedEventInterest = sEventInterest;
        //let otherEIndex = updatedEventInterest.indexOf("Other (please specify)");
        //updatedEventInterest.splice(otherEIndex, 1, )

        infoContext.setInfo((info) => ({
            ...info,
            clubs: sClubs,
            credits: sCredits,
            cunyCollege: sCunyCollege,
            degree: sDegree,
            eduYear: sEduYear,
            eventInterest: sEventInterest,
            otherEventInterest: otherEventInterest,
            gender: sGender,
            gpa: sGPA,
            graduationDate: sGradDate,
            linkedIn: sLinkedIn,
            major: sMajor,
            otherCunyCollege: otherCunyCollege,
            phone: sPhone,
            race: sRace,
            heardAbout: sHeardAbout,
            zip: sZip,
            updated: true,
        }));

        let token = window.sessionStorage.getItem("accessToken");

        let headers = new Headers();
        headers.append("Authorization", `Bearer ${token}`);
        headers.append("Content-Type", "application/json");

        let updatedEventInterests = sEventInterest ? [...sEventInterest] : [];

        let otherEventIndex = updatedEventInterests?.indexOf(
            "Other (please specify)"
        );
        if (otherEventIndex !== -1 || otherEventIndex === 0)
            updatedEventInterests.splice(otherEventIndex, 1, otherEventInterest);

        let payload = {
            CLUBS: sClubs,
            CREDITS: sCredits,
            CUNY_COLLEGE: otherCunyCollege || sCunyCollege,
            DEGREE_TYPE: sDegree,
            EDUCATION_YEAR: sEduYear,
            EVENT_INTEREST: updatedEventInterests?.join(","),
            GENDER: sGender,
            GPA: sGPA,
            GRADUATION_DATE: sGradDate,
            LINKEDIN_URL: sLinkedIn,
            MAJOR: sMajor,
            PHONE: sPhone,
            RACE: sRace,
            ZIPCODE: sZip,
            HEARD_ABOUT: sHeardAbout,
            UPDATED_AT: new Date().toISOString().slice(0, 10),
        };
        let options = {
            method: "POST",
            headers: headers,
            redirect: "follow",
            body: JSON.stringify(payload),
        };

        let url = `${globalURLContext.globalURL}/demographics`;

        await fetch(url, options)
            .then((response) => {
                if (response.status === 401) {
                    setLoggedIn(false);
                    window.location.reload();
                    window.sessionStorage.clear();
                    throw new Error("Authentication Error");
                }
                if (!response.ok)
                    throw new Error(`Unexpected response status ${response.status}`);
                setIsLoadingDemog(false);
                /*setSnackbarOpen(true);
                                    setSnackbarMessage("Thank you for updating your info!");
                                    setTimeout(() => setSnackbarOpen(false), 6000);*/
                getEvents();
            })
            .catch((e) => {
                console.log(e);
                /*setSnackbarOpen(true);
                                    setSnackbarMessage("There was a problem with updating your info. Please try again later.");
                                    setTimeout(() => setSnackbarOpen(false), 6000);*/
            });
    }

    async function handleNext1(e) {
        //if (employmentUpdated) {
            let flag = 0;
            if (!sEmploymentStatus) {
                setInvalidEmploymentStatus(true);
                console.log("emplo stat");
                flag = 1;
            } else {
                if (sEmploymentStatus === "I am a full-time employee" && !sSalary) {
                    setInvalidSalary(true);
                    console.log("salary");
                    flag = 1;
                }
                if (
                    sEmploymentStatus !== "I am looking for full-time employment" &&
                    sEmploymentStatus !== "I am a full-time student" &&
                    sEmploymentStatus !== "I am a full-time employee" &&
                    !sWage
                ) {
                    setInvalidWage(true);
                    console.log("wage");
                    flag = 1;
                }/*
                if (!sStudentStatus) {
                    setInvalidStudentStatus(true);
                    console.log("student status");
                    flag = 1;
                }*/
                if (
                    sEmploymentStatus !== "I am a full-time student" &&
                    sEmploymentStatus !== "I am looking for full-time employment"
                ) {
                    if (!sCompany) {
                        setInvalidCompany(true);
                        flag = 1;
                    } else if (
                        sCompany === "Other (please specify)" &&
                        (!validateInput(otherCompany, "company")  || !otherCompany)
                    ) {
                        setInvalidOtherCompany(true);
                        flag = 1;
                    }
                }
                /*if (!sSource) {
                    setInvalidSource(true);
                    flag = 1;
                } else if (
                    sSource === "Other (please specify)" &&
                    !validateInput(otherSource, "source")
                ) {
                    setInvalidOtherSource(true);
                    flag = 1;
                }*/
            //}
            //}
            /*if (!sEmploymentStatus || 
                    (sEmploymentStatus === "I am a full-time employee" && !sSalary) ||
                    (sEmploymentStatus !== "I am a full-time employee" && sEmploymentStatus !== "I am looking for full-time employment" && sEmploymentStatus !== "I am a full-time student" && !sWage) ||
                    (!sStudentStatus) ||
                    (!sCompany) || !sSource
                    ) return*/
            if (flag) {
                console.log("error invalid data");
                return;
            }
        /*if (
            sSource === "Other (please specify)" &&
            !validateInput(otherSource, "source")
        ) {
            setInvalidOtherSource(true);
            return;
        }*/
            setPhase("demographics");
            if (resumeUpdated) {
                uploadResume();
                setResumeUploaded(true);
            }
            registerRClick("next-btn-employment");
        }
    }

    async function handleNext2(e) {
        let flag = 0;

        /*} if (!sGender) {
                setInvalidGender(true);
                flag = 1;
            }
            if (!sRace) {
                setInvalidRace(true);
                flag = 1;
            }*/
            if (!sCunyCollege) {
                setInvalidCunyCollege(true);
                flag = 1;
            }
    /*
            if (!sHeardAbout) {
                setInvalidHeardAbout(true);
                flag = 1;
            }
            if (!sPhone) {
                setInvalidPhone(true);
                flag = 1;
            }
            if (!sZip) {
                setInvalidZip(true);
                flag = 1;
            }
    
            if (!sEventInterest || sEventInterest?.length === 0) {
                setInvalidEventInterest(true);
                flag = 1;
            }
            if (sCunyCollege !== "Never enrolled/Did not graduate") {
                if (!sMajor) {
                    setInvalidMajor(true);
                    flag = 1;
                }
                if (!sCredits) {
                    setInvalidCredits(true);
                    flag = 1;
                }
                if (!sGPA) {
                    setInvalidGPA(true);
                    flag = 1;
                }
                if (!sEduYear) {
                    setInvalidEducationYear(true);
                    flag = 1;
                }
                if (!sGradDate) {
                    setInvalidGradDate(true);
                    flag = 1;
                }
                if (!sDegree) {
                    setInvalidDegree(true);
                    flag = 1;
                }
            }*/

        if (!validateInput(sZip, "zip")) {
            setInvalidZip(true);
            flag = 1;
        }
        if (!validateInput(sPhone, "phone")) {
            setInvalidPhone(true);
            flag = 1;
        }
        if (!validateInput(sLinkedIn, "linkedin")) {
            setInvalidLinkedIn(true);
            flag = 1;
        }
        if (!validateInput(sClubs, "clubs")) {
            setInvalidClubs(true);
            flag = 1;
        }
        if (
            sEventInterest?.includes("Other (please specify)") &&
            !validateInput(otherEventInterest, "event_interest")
        ) {
            setInvalidOtherEventInterest(true);
            flag = 1;
        }
        if (
            sCunyCollege === "Other (please specify)" &&
            (!validateInput(otherCunyCollege, "event_interest") || !otherCunyCollege)
        ) {
            setInvalidOtherCunyCollege(true);
            flag = 1;
        }
        if (!validateInput(sGradDate, "grad_date")) {
            setInvalidGradDate(true);
            flag = 1;
        }
        if (flag) {
            return;
        } else {
            if (
                !demographicsUpdated &&
                !employmentUpdated &&
                sResumeUploaded &&
                !resumeUpdated
            ) {
                navigate("/home");
                registerRClick("next-btn-demographics");
                return;
            }
            navigate("/home");
            postInfo();
            registerRClick("next-btn-demographics");
            return;
        }
    }

    async function handleSave1(e) {
        let flag = 0;
        if (!sEmploymentStatus) {
            setInvalidEmploymentStatus(true);
            flag = 1;
        } else {
            if (sEmploymentStatus === "I am a full-time employee" && !sSalary) {
                setInvalidSalary(true);
                flag = 1;
            }
            if (
                sEmploymentStatus !== "I am looking for full-time employment" &&
                sEmploymentStatus !== "I am a full-time student" &&
                sEmploymentStatus !== "I am a full-time employee" &&
                !sWage
            ) {
                setInvalidWage(true);
                flag = 1;
            }
        
        /*if (!sStudentStatus) {
            setInvalidStudentStatus(true);
            flag = 1;
        }*/

            if (
                sEmploymentStatus !== "I am a full-time student" &&
                sEmploymentStatus !== "I am looking for full-time employment"
            ) {
                if (!sCompany) {
                    setInvalidCompany(true);
                    console.log("company");
                    flag = 1;
                } else if (
                    sCompany === "Other (please specify)" &&
                    (!validateInput(otherCompany, "company") || !otherCompany)
                ) {
                    setInvalidOtherCompany(true);
                    console.log("other company");
                    flag = 1;
                }
                /*if (!sSource) {
                    console.log("source");
                    setInvalidSource(true);
                    flag = 1;
                } else if (
                    sSource === "Other (please specify)" &&
                    !validateInput(otherSource, "source")
                ) {
                    console.log("o source");
                    setInvalidOtherSource(true);
                    flag = 1;
                }*/
            }
        }
        if (flag) {
            return;
        }
        /*if (
            sSource === "Other (please specify)" &&
            !validateInput(otherSource, "source")
        ) {
            setInvalidOtherSource(true);
            return;
        }*/
        postEmploymentInfo();

        if (resumeUpdated) {
            uploadResume();
            setResumeUploaded(true);
        }

        setEmploymentUpdated(false);
        setResumeUpdated(false);
        registerRClick("save-employment");
    }

    async function handleSave2(e) {
        let flag = 0;

        /*if (!sGender) {
                setInvalidGender(true);
                flag = 1;
            }
            if (!sRace) {
                setInvalidRace(true);
                flag = 1;
            }*/
            if (!sCunyCollege) {
                setInvalidCunyCollege(true);
                flag = 1;
            }
    /*
            if (!sHeardAbout) {
                setInvalidHeardAbout(true);
                flag = 1;
            }
            if (!sPhone) {
                setInvalidPhone(true);
                flag = 1;
            }
            if (!sZip) {
                setInvalidZip(true);
                flag = 1;
            }
    
            if (!sEventInterest || sEventInterest?.length === 0) {
                setInvalidEventInterest(true);
                flag = 1;
            }
            if (sCunyCollege !== "Never enrolled/Did not graduate") {
                if (!sMajor) {
                    setInvalidMajor(true);
                    flag = 1;
                }
                if (!sCredits) {
                    setInvalidCredits(true);
                    flag = 1;
                }
                if (!sGPA) {
                    setInvalidGPA(true);
                    flag = 1;
                }
                if (!sEduYear) {
                    setInvalidEducationYear(true);
                    flag = 1;
                }
                if (!sGradDate) {
                    setInvalidGradDate(true);
                    flag = 1;
                }
                if (!sDegree) {
                    setInvalidDegree(true);
                    flag = 1;
                }
            }*/

        if (!validateInput(sZip, "zip")) {
            setInvalidZip(true);
            flag = 1;
        }
        if (!validateInput(sPhone, "phone")) {
            setInvalidPhone(true);
            flag = 1;
        }
        if (!validateInput(sLinkedIn, "linkedin")) {
            setInvalidLinkedIn(true);
            flag = 1;
        }
        if (!validateInput(sClubs, "clubs")) {
            setInvalidClubs(true);
            flag = 1;
        }
        if (
            sEventInterest?.includes("Other (please specify)") &&
            !validateInput(otherEventInterest, "event_interest")
        ) {
            setInvalidOtherEventInterest(true);
            flag = 1;
        }
        // TODO create specific regex for college name
        if (
            sCunyCollege === "Other (please specify)" &&
            (!validateInput(otherCunyCollege, "event_interest") || !otherCunyCollege)
        ) {
            setInvalidOtherCunyCollege(true);
            flag = 1;
        }
        if (!validateInput(sGradDate, "grad_date")) {
            setInvalidGradDate(true);
            flag = 1;
        }

        if (flag) return;
        // Not needed because save button enabled when demographics are updated?
        if (!demographicsUpdated) return;
        postDemographicInfo();
        setDemographicsUpdated(false);
        registerRClick("save-demographics");
    }

    async function handleBack() {
        setPhase("employment");
        setResumeUpdated(false);
        setTempCompany(sCompany);
    }

    function filterItems(menu) {
        return menu?.item?.toLowerCase()?.includes(menu?.inputValue?.toLowerCase());
    }

    const handleDrop = (e) => {
        e.preventDefault();
    };

    const handleDragover = (e) => {
        e.preventDefault();
    };

    useEffect(() => {
        document.addEventListener("drop", handleDrop);
        document.addEventListener("dragover", handleDragover);
        return () => {
            document.removeEventListener("drop", handleDrop);
            document.removeEventListener("dragover", handleDragover);
        };
    }, [phase]);

    function uploadResume() {
        const form = document.getElementById("resume-upload");
        const upload = form?.childNodes?.[0]?.files?.[0];
        if (upload?.type !== "application/pdf") return;
        if (upload?.size >= 3 * Math.pow(10, 6)) return;
        if (upload?.name?.length > 40) return;
        let token = window.sessionStorage.getItem("accessToken");
        let headers = new Headers();
        headers.append("Authorization", `Bearer ${token}`);

        const formData = new FormData(form);
        fetch(`${globalURLContext.globalURL}/resume`, {
            method: "POST",
            headers: headers,
            redirect: "follow",
            body: formData,
        })
            .then((response) => response.json())
            .then((result) => {
                console.log(result);
            });
    }

    return (
        <div
            className={`profile-content ${isCheckIn ? "profile-content-checkin" : "profile-content-no-checkin"
                }`}
        >
            {phase === "employment" && <div
                className="help-us-help-you"
            >
                <div style={{
                    fontSize: "14px"
                }}>Help us, help you! Our portal provides users with job
                    opportunities, resources, and event opportunities based on
                    your current job status and educational experience. The more
                    we know about you, the more we can help you find a perfect
                    opportunity! Please take a moment to complete your profile.
                </div>
            </div>}
            <div className="profile-c" style={{ marginTop: phase === "employment" ? "18px" : "48px" }} >
                <>
                    {(phase === "employment" || !isCheckIn) && (
                        <>
                            {isCheckIn ? (
                                <>
                                    <div className="card-title">
                                        <h4 className="page-title employ-checkin-title profile-card-title">
                                            First off, let's make sure your employment info is
                                            up-to-date.
                                            <br />
                                            Please update anything that is incorrect or incomplete...
                                        </h4>
                                    </div>

                                </>
                            ) : (
                                <div className="card-title">
                                    <h4 className="page-title employ-profile-title profile-card-title">
                                        Employment Info
                                        {!isCheckIn && (
                                            <button
                                                id="save-employment"
                                                onClick={handleSave1}
                                                disabled={!employmentUpdated && !resumeUpdated}
                                                style={{
                                                    position: "absolute",
                                                    right: "12px",
                                                    top: "5px",
                                                    border: `2px solid ${!employmentUpdated && !resumeUpdated
                                                        ? "#e0e0e0": "#FAB57F"
                                                        }`,
                                                    backgroundColor: "white",
                                                    borderRadius: 10,
                                                    padding: "5px 10px",
                                                }}
                                            >
                                                <SaveIcon
                                                    sx={{
                                                        color: !employmentUpdated && !resumeUpdated ? "#e0e0e0":"var(--bs-primary)",
                                                        position: "relative",
                                                        top: -1,
                                                    }}
                                                />
                                            </button>
                                        )}
                                    </h4>
                                </div>
                            )}
                            <div className={`info ${isCheckIn ? "info-checkin1" : ""}`}>
                                <div className="profile-employ-col">
                                    {isLoading ? (
                                        <SkeletonPlaceholder style={{ marginTop: "24px" }} className="dd skel" />
                                    ) : (
                                        <Dropdown
                                            light
                                            className="dd"
                                            invalid={invalidEmploymentStatus}
                                            selectedItem={sEmploymentStatus}
                                            label="Employment Status*"
                                            titleText="Employment Status*"
                                            items={employmentStatus}
                                            onChange={({ selectedItem }) => {
                                                setEmploymentStatus(selectedItem);
                                                setInvalidEmploymentStatus(false);
                                                setEmploymentUpdated(true);
                                            }}
                                        ></Dropdown>
                                    )}
                                    {isLoading ? (
                                        <SkeletonPlaceholder className="dd skel" />
                                    ) : sEmploymentStatus === "I am a full-time employee" ? (
                                        <Dropdown
                                            light
                                            className="dd"
                                            invalid={invalidSalary}
                                            selectedItem={sSalary || ""}
                                            label="Annual Salary"
                                            titleText="Annual Salary*"
                                            items={salary}
                                            onChange={({ selectedItem }) => {
                                                setSalary(selectedItem);
                                                setInvalidSalary(false);
                                                setEmploymentUpdated(true);
                                            }}
                                        ></Dropdown>
                                    ) : (
                                        <Dropdown
                                            invalid={
                                                invalidWage &&
                                                sEmploymentStatus !==
                                                "I am looking for full-time employment" &&
                                                sEmploymentStatus !== "I am a full-time student"
                                            }
                                            disabled={
                                                sEmploymentStatus ===
                                                "I am looking for full-time employment" ||
                                                sEmploymentStatus === "I am a full-time student"
                                            }
                                            light
                                            className="dd"
                                            selectedItem={sWage || ""}
                                            label="Hourly Wage"
                                            titleText="Hourly Wage*"
                                            items={wage}
                                            onChange={({ selectedItem }) => {
                                                setWage(selectedItem);
                                                setInvalidWage(false);
                                                setEmploymentUpdated(true);
                                            }}
                                        ></Dropdown>
                                    )}
                                    {isLoading ? (
                                        <SkeletonPlaceholder className="dd skel" />
                                    ) : (
                                        <Dropdown
                                            light
                                            className="dd"
                                            invalid={invalidStudentStatus}
                                            selectedItem={sStudentStatus}
                                            label="Student Status"
                                            titleText="Student Status"
                                            items={studentStatus}
                                            onChange={({ selectedItem }) => {
                                                setStudentStatus(selectedItem);
                                                setInvalidStudentStatus(false);
                                                setEmploymentUpdated(true);
                                            }}
                                        ></Dropdown>
                                    )}
                                </div>
                                <div className="profile-employ-col">
                                    {isLoading ? (
                                        <SkeletonPlaceholder style={{ marginTop: "24px" }} className="dd skel" />
                                    ) : (
                                        <div style={{ display: "flex" }}>
                                            <Dropdown
                                                disabled={
                                                    sEmploymentStatus ===
                                                    "I am looking for full-time employment" ||
                                                    sEmploymentStatus === "I am a full-time student"
                                                }
                                                light
                                                invalid={
                                                    invalidCompany &&
                                                    sEmploymentStatus !==
                                                    "I am looking for full-time employment" &&
                                                    sEmploymentStatus !== "I am a full-time student"
                                                }
                                                id="carbon-combobox"
                                                //downshiftProps={{
                                                //    onStateChange: () => { },
                                                //}}
                                                //placeholder="Enter company"
                                                //initialSelectedItem={sCompany}
                                                label="Company"
                                                titleText="Company*"
                                                items={company}
                                                className="dd"
                                                selectedItem={sCompany}
                                                onChange={({ selectedItem }) => {
                                                    setCompany(selectedItem);
                                                    //setTempCompany(selectedItem);
                                                    setOtherCompany("");
                                                    setInvalidCompany(false);
                                                    setInvalidOtherCompany(false);
                                                    setEmploymentUpdated(true);
                                                }}
                                                //onInputChange={(e) => {setTempCompany(e); setInvalidCompany(false)}}
                                                //shouldFilterItem={filterItems}
                                                //value={tempCompany}
                                            />
                                            {sCompany === "Other (please specify)" && (
                                                <TextInput
                                                    light
                                                    disabled={
                                                        sEmploymentStatus ===
                                                        "I am looking for full-time employment" ||
                                                        sEmploymentStatus === "I am a full-time student"
                                                    }
                                                    invalid={invalidOtherCompany}
                                                    className="ti right o-company"
                                                    value={otherCompany}
                                                    labelText="Please specify"
                                                    style={{ borderRadius: "5px 5px" }}
                                                    onChange={(e) => {
                                                        setOtherCompany(e.target.value);
                                                        setInvalidOtherCompany(false);
                                                        setEmploymentUpdated(true);
                                                    }}
                                                ></TextInput>
                                            )}
                                        </div>
                                    )}
                                    {isLoading ? (
                                        <SkeletonPlaceholder className="dd skel" />
                                    ) : (
                                        <div style={{ display: "flex" }}>
                                            <Dropdown
                                                invalid={
                                                    invalidSource &&
                                                    sEmploymentStatus !==
                                                    "I am looking for full-time employment" &&
                                                    sEmploymentStatus !== "I am a full-time student"
                                                }
                                                disabled={
                                                    sEmploymentStatus ===
                                                    "I am looking for full-time employment" ||
                                                    sEmploymentStatus === "I am a full-time student"
                                                }
                                                light
                                                className="dd"
                                                selectedItem={sSource}
                                                label="Source of Employment"
                                                titleText="Source of Employment"
                                                items={source}
                                                onChange={({ selectedItem }) => {
                                                    setSource(selectedItem);
                                                    setInvalidSource(false);
                                                    setOtherSource("");
                                                    setEmploymentUpdated(true);
                                                }}
                                            ></Dropdown>
                                            {sSource === "Other (please specify)" && (
                                                <TextInput
                                                    style={{ borderRadius: "5px 5px" }}
                                                    light
                                                    invalid={invalidOtherSource}
                                                    className="ti right o-source"
                                                    value={otherSource}
                                                    disabled={
                                                        sEmploymentStatus ===
                                                        "I am looking for full-time employment" ||
                                                        sEmploymentStatus === "I am a full-time student"
                                                    }
                                                    labelText="Please specify"
                                                    onChange={(e) => {
                                                        setOtherSource(e.target.value);
                                                        setInvalidSource(false);
                                                        setInvalidOtherSource(false);
                                                        setEmploymentUpdated(true);
                                                    }}
                                                ></TextInput>
                                            )}
                                        </div>
                                    )}
                                    {isLoading ? (
                                        <SkeletonPlaceholder className="dd skel" />
                                    ) : sResumeUploaded && !resumeUpdateReq ? (
                                        <div className="resume-update-req">
                                            <div style={{ marginBottom: 8, color: "rgb(91,89,89" }}>
                                                You already uploaded your resume
                                            </div>{" "}
                                            <button
                                                style={{
                                                    borderRadius: "5px 5px",
                                                    backgroundColor: "white",
                                                    minWidth: "100%",
                                                    border: "0px",
                                                    minHeight: 40,
                                                    borderBottom: "1px solid rgb(91,89,89,0.7)",
                                                    paddingLeft: 16,
                                                    textAlign: "left",
                                                    fontSize: "14px",
                                                }}
                                                onClick={() => setResumeUpdateReq(true)}
                                            >
                                                Update resume
                                            </button>
                                        </div>
                                    ) : (
                                        <div style={{ marginTop: 0}}>
                                            <div className="cds--label" style={{ marginBottom: 8 }}>
                                                Upload Resume (PDF &lt; 3MB)
                                            </div>
                                            <form
                                                method="post"
                                                id="resume-upload"
                                                target="_blank"
                                                encType="multipart/form-data"
                                            >
                                                <input
                                                    style={{
                                                        fontSize: 14,
                                                        backgroundColor: "white",
                                                        padding: "6px 8px",
                                                        width: "100%",
                                                        maxWidth: "100%",
                                                        borderRadius: "5px 5px",
                                                        border:"1px solid rgb(91,89,89,0.7)"
                                                    }}
                                                    onChange={() => setResumeUpdated(true)}
                                                    type="file"
                                                    name="resume"
                                                    id="resume-input"
                                                    accept=".pdf"
                                                />
                                            </form>
                                        </div>
                                    )}
                                    {!isLoading && (
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "end",
                                                marginTop: 24,
                                            }}
                                        >
                                            {isCheckIn && (
                                                <button
                                                    id="next-btn-employment"
                                                    className="next-btn-profile next-employ"
                                                    onClick={
                                                        handleNext1
                                                    }
                                                >
                                                    <span style={{ marginRight: 24 }}>Next</span>
                                                    <ArrowForwardIcon
                                                        style={{ position: "relative", top: "0px" }}
                                                    />
                                                </button>
                                            )}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </>
                    )}
                    {(phase === "demographics" || !isCheckIn) && (
                        <div
                            style={{
                                marginTop: isCheckIn ? 0 : 55,
                                marginBottom: isCheckIn ? 0 : 155,
                            }}
                        >
                            {isCheckIn ? (
                                <div className="card-title" >
                                    <h4 className="demog-checkin-title profile-card-title">
                                        Next, please make sure your profile info is accurate...
                                    </h4>
                                </div>
                            ) : (
                                <div className="card-title">
                                    <h4 className="page-title demog-profile-title profile-card-title">
                                        Profile Info
                                        {!isCheckIn && (
                                            <button
                                                id="save-demographics"
                                                onClick={handleSave2}
                                                disabled={!demographicsUpdated}
                                                style={{
                                                    position: "absolute",
                                                    right: "12px",
                                                    top: "5px",
                                                    border: `2px solid ${!demographicsUpdated ? "#e0e0e0" : "#FAB57F"
                                                        }`,
                                                    backgroundColor: "white",
                                                    borderRadius: 10,
                                                    padding: "5px 10px",
                                                }}
                                            >
                                                <SaveIcon
                                                    sx={{
                                                        color: !demographicsUpdated ? "#e0e0e0" : "var(--bs-primary)",
                                                        position: "relative",
                                                        top: -1,
                                                    }}
                                                />
                                            </button>
                                        )}
                                    </h4>
                                </div>
                            )}
                            <div className={`info info2}`}>
                                <div className="profile-demog-col">
                                    <div style={{ display: "flex", gap: 4 }}>
                                        <Dropdown
                                            light
                                            className="dd"
                                            invalid={invalidGender}
                                            selectedItem={sGender}
                                            label="Gender"
                                            titleText="Gender"
                                            items={gender}
                                            onChange={({ selectedItem }) => {
                                                setGender(selectedItem);
                                                setDemographicsUpdated(true);
                                                setInvalidGender(false);
                                            }}
                                        ></Dropdown>
                                    </div>
                                    <div style={{ display: "flex", gap: 4 }}>
                                        <Dropdown
                                            light
                                            className="dd"
                                            invalid={invalidCunyCollege}
                                            selectedItem={sCunyCollege}
                                            label="College"
                                            titleText="College*"
                                            items={cunyCollege}
                                            onChange={({ selectedItem }) => {
                                                setCunyCollege(selectedItem);
                                                setOtherCunyCollege(
                                                    ""
                                                );
                                                setDemographicsUpdated(true);
                                                setInvalidCunyCollege(false);
                                            }}
                                        ></Dropdown>
                                        {sCunyCollege === "Other (please specify)" && (
                                            <TextInput
                                                light
                                                invalid={invalidOtherCunyCollege}
                                                value={otherCunyCollege}
                                                style={{ minWidth: 120, position: "relative", top: -1 }}
                                                className="ti"
                                                onChange={(e) => {
                                                    setOtherCunyCollege(e.target.value);
                                                    setInvalidOtherCunyCollege(false);
                                                    setDemographicsUpdated(true);
                                                }}
                                                labelText="Please specify"
                                            ></TextInput>
                                        )}
                                    </div>
                                    <Dropdown
                                        disabled={
                                            sCunyCollege === "Never enrolled/Did not graduate"
                                        }
                                        light
                                        invalid={invalidDegree}
                                        className="dd"
                                        initialSelectedItem={sDegree}
                                        label="Degree"
                                        titleText="Degree"
                                        items={degree}
                                        onChange={({ selectedItem }) => {
                                            setDegree(selectedItem);
                                            setDemographicsUpdated(true);
                                            setInvalidDegree(false);
                                        }}
                                    ></Dropdown>
                                    <Dropdown
                                        disabled={
                                            sCunyCollege === "Never enrolled/Did not graduate"
                                        }
                                        light
                                        invalid={invalidMajor}
                                        className="dd"
                                        selectedItem={sMajor}
                                        label="Major"
                                        titleText="Major"
                                        items={major}
                                        onChange={({ selectedItem }) => {
                                            setMajor(selectedItem);
                                            setInvalidMajor(false);
                                            setDemographicsUpdated(true);
                                        }}
                                    ></Dropdown>
                                    <div style={{ display: "flex", gap: 4 }}>
                                        <TextInput
                                            light
                                            id="phone"
                                            style={{ borderRadius: "5px 5px" }}
                                            placeholder="123-456-7890"
                                            invalid={invalidPhone}
                                            className="ti right"
                                            value={sPhone}
                                            labelText="Phone number"
                                            onChange={(e) => {
                                                setPhone(e.target.value);
                                                setDemographicsUpdated(true);
                                                setInvalidPhone(false);
                                            }}
                                        ></TextInput>
                                        <TextInput
                                            light
                                            id="zip"
                                            style={{ borderRadius: "5px 5px" }}
                                            invalid={invalidZip}
                                            className="ti"
                                            value={sZip}
                                            labelText="Zip code"
                                            onChange={(e) => {
                                                setZip(e.target.value);
                                                setDemographicsUpdated(true);
                                                setInvalidZip(false);
                                            }}
                                        ></TextInput>
                                    </div>
                                    <div style={{ display: "flex" }}>
                                        <MultiSelect
                                            light
                                            style={{ borderRadius: "5px 5px", maxWidth: "calc(100% - 100px)" }}
                                            className="dd"
                                            invalid={invalidEventInterest}
                                            selectedItems={sEventInterest}
                                            label="Event Interests"
                                            titleText="Event Interests"
                                            items={eventInterest}
                                            onChange={({ selectedItems }) => {
                                                setEventInterest(selectedItems);
                                                setOtherEventInterest(
                                                    selectedItems?.includes("Other (please specify)")
                                                        ? otherEventInterest
                                                        : ""
                                                );
                                                setDemographicsUpdated(true);
                                                setInvalidEventInterest(false);
                                            }}
                                        ></MultiSelect>
                                        {sEventInterest?.includes("Other (please specify)") && (
                                            <TextInput
                                                light
                                                style={{ borderRadius: "5px 5px", minWidth: "100px" }}
                                                invalid={invalidOtherEventInterest}
                                                className={`ti right ${isCheckIn
                                                    ? "other-event-interest-checkin"
                                                    : "other-event-interest-profile"
                                                    }`}
                                                value={otherEventInterest}
                                                labelText="Please specify"
                                                onChange={(e) => {
                                                    setOtherEventInterest(e.target.value);
                                                    setInvalidOtherEventInterest(false);
                                                    setDemographicsUpdated(true);
                                                }}
                                            ></TextInput>
                                        )}
                                    </div>
                                </div>
                                <div className="profile-demog-col">
                                    <Dropdown
                                        light
                                        invalid={invalidRace}
                                        className="dd"
                                        selectedItem={sRace}
                                        label="Race/Ethnicity"
                                        titleText="Race/Ethnicity"
                                        items={race}
                                        onChange={({ selectedItem }) => {
                                            setRace(selectedItem);
                                            setDemographicsUpdated(true);
                                            setInvalidRace(false);
                                        }}
                                    ></Dropdown>
                                    <div style={{ display: "flex", gap:"2px" }}>
                                        <Dropdown
                                            disabled={
                                                sCunyCollege === "Never enrolled/Did not graduate"
                                            }
                                            light
                                            invalid={invalidEducationYear}
                                            className="dd edu-year"
                                            selectedItem={sEduYear}
                                            label="Education Year"
                                            titleText="Education Year"
                                            items={eduYear}
                                            onChange={({ selectedItem }) => {
                                                setEduYear(selectedItem);
                                                setInvalidEducationYear(false);
                                                setDemographicsUpdated(true);
                                            }}
                                        ></Dropdown>
                                        <Dropdown
                                            disabled={
                                                sCunyCollege === "Never enrolled/Did not graduate"
                                            }
                                            light
                                            invalid={invalidCredits}
                                            className="credits"
                                            selectedItem={sCredits}
                                            label="Credits"
                                            titleText="Credits"
                                            items={credits}
                                            onChange={({ selectedItem }) => {
                                                setCredits(selectedItem);
                                                setInvalidCredits(false);
                                                setDemographicsUpdated(true);
                                            }}
                                        ></Dropdown>
                                    </div>
                                    <div style={{ display: "flex", gap: "4px" }}>
                                        <Dropdown
                                            disabled={
                                                sCunyCollege === "Never enrolled/Did not graduate"
                                            }
                                            light
                                            invalid={invalidGPA}
                                            className="dd"
                                            selectedItem={sGPA}
                                            label="GPA"
                                            titleText="GPA"
                                            items={gpa}
                                            onChange={({ selectedItem }) => {
                                                setGPA(selectedItem);
                                                setInvalidGPA(false);
                                                setDemographicsUpdated(true);
                                            }}
                                        ></Dropdown>
                                        <DatePicker
                                            disabled={
                                                sCunyCollege === "Never enrolled/Did not graduate"
                                            }
                                            className="date"
                                            light
                                            dateFormat="mm/YYYY"
                                            datePickerType="simple"
                                        >
                                            <DatePickerInput
                                                disabled={
                                                    sCunyCollege === "Never enrolled/Did not graduate"
                                                }
                                                id="date-picker"
                                                invalid={invalidGradDate}
                                                placeholder="MM/YYYY"
                                                labelText="(Expect.) Grad. Date"
                                                type="text"
                                                style={{ 
                                                    borderRadius: "5px 5px",
                                                    border: "1px solid rgb(91,89,89,0.7)" 
                                                }}
                                                onChange={(e) => {
                                                    setGradDate(e.target.value);
                                                    setInvalidGradDate(false);
                                                    setDemographicsUpdated(true);
                                                }}
                                                value={sGradDate}
                                            />
                                        </DatePicker>
                                    </div>
                                    <TextInput
                                        light
                                        invalid={invalidClubs}
                                        className="ti"
                                        value={sClubs}
                                        labelText="Student Clubs/Student Organizations"
                                        onChange={(e) => {
                                            setClubs(e.target.value);
                                            setInvalidClubs(false);
                                            setDemographicsUpdated(true);
                                        }}
                                        style={{ borderRadius: "5px 5px" }}
                                    ></TextInput>
                                    <TextInput
                                        light
                                        style={{ borderRadius: "5px 5px" }}
                                        invalid={invalidLinkedIn}
                                        className="ti"
                                        value={sLinkedIn}
                                        labelText="LinkedIn profile URL"
                                        onChange={(e) => {
                                            setLinkedIn(e.target.value);
                                            setInvalidLinkedIn(false);
                                            setDemographicsUpdated(true);
                                        }}
                                    ></TextInput>
                                    <Dropdown
                                        light
                                        className="dd"
                                        invalid={invalidHeardAbout}
                                        selectedItem={sHeardAbout}
                                        label="How did you hear about us?"
                                        titleText="How did you hear about us?"
                                        items={heardAbout}
                                        onChange={({ selectedItem }) => {
                                            setHeardAbout(selectedItem);
                                            setInvalidHeardAbout(false);
                                            setDemographicsUpdated(true);
                                        }}
                                    ></Dropdown>
                                </div>
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        width: "100%",
                                    }}
                                >
                                    {isCheckIn && (
                                        <button className="back-btn" onClick={handleBack}>
                                            <ArrowLeft style={{ position: "relative", top: "0px" }} />
                                            <span style={{ marginLeft: 18 }}>Back</span>
                                        </button>
                                    )}
                                    <div style={{ display: "flex", justifyContent: "end" }}>
                                        {
                                            isCheckIn && (
                                                <button
                                                    id="next-btn-demographics"
                                                    className="next-btn-profile"
                                                    onClick={handleNext2}
                                                >
                                                    <span style={{ marginRight: 24 }}>Next</span>
                                                    <ArrowForwardIcon
                                                        style={{ position: "relative", top: "0px" }}
                                                    />
                                                </button>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </>
            </div>
        </div >
    );
}

export default ProfileComponent;
