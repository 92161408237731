import React, { useEffect, useState, useContext, useRef } from "react";
import {
    TextInput,
} from "@carbon/react";
import Footer from "../../components/Footer";
import Menu from "../../components/Menu";
import Spinner from 'react-bootstrap/Spinner';
import SaveIcon from '@mui/icons-material/Save';
import '../../index.scss';
import './_Account.scss';
import GlobalURLContext from "../../components/GlobalURLContext";
import InfoContext from "../../components/InfoContext";
import { validateInput } from "../../Helpers";



function Account() {

    const globalURLContext = useContext(GlobalURLContext);
    const infoContext = useContext(InfoContext);

    const [isLoading, setIsLoading] = useState(false);
    const [saveDisabled, setSaveDisabled] = useState(true);

    const [firstName, setFirstName] = useState(infoContext.info?.firstName);
    const [lastName, setLastName] = useState(infoContext.info?.lastName);
    const [email, setEmail] = useState(infoContext.info?.email);

    const [invalidFirstName, setInvalidFirstName] = useState(false);
    const [invalidLastName, setInvalidLastName] = useState(false);
    const [invalidEmail, setInvalidEmail] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0);

        if (!infoContext.info?.firstName || !infoContext.info?.lastName || !infoContext.info?.email) {
            getInfo();
        }
    }, []);

    function handleSave() {
        //e.preventDefault();
        if (saveDisabled) return;

        let flag = 0;
        if (!validateInput(firstName, "name")) {
            setInvalidFirstName(true);
            flag = 1;
        }
        if (!validateInput(lastName, "name")) {
            setInvalidLastName(true);
            flag = 1;
        }
        if (!validateInput(email, "email")) {
            setInvalidEmail(true);
            flag = 1;
        }
        if (flag) return

        let headers = new Headers();
        let token = window.sessionStorage.getItem("accessToken")
        headers.append("Authorization", `Bearer ${token}`);
        headers.append("Content-Type", "application/json");
        headers.append("Accept", "application/json");
        let options = {
            headers: headers,
            redirect: "follow",
            method: "POST",
            body: JSON.stringify({
                FIRST_NAME: firstName,
                LAST_NAME: lastName,
                EMAIL1: email
            })
        }
        let url = `${globalURLContext.globalURL}/userinfo`;
        setSaveDisabled(true);

        fetch(url, options
        ).then(response => {
            if (!response.ok) throw new Error(`Unexpected response status ${response.status}`)
            else infoContext.setInfo(info => ({ ...info, firstName: firstName, lastName: lastName, email: email }));
        }).catch(e => console.log(e));

    }

    function getInfo() {
        const controller = new AbortController();
        const timeoutId = setTimeout(() => controller.abort(), 60000);
        setIsLoading(true);
        let headers = new Headers();
        let token = window.sessionStorage.getItem("accessToken")
        headers.append("Authorization", `Bearer ${token}`)
        let options = {
            headers: headers,
            redirect: "follow",
            signal: controller.signal
        }
        let url = `${globalURLContext.globalURL}/userinfo`;

        fetch(url, options
        ).then(response => {
            if (!response.ok) throw new Error(`Unexpected response status ${response.status}`)
            else return response.json()
        }).then(({ FIRST_NAME, LAST_NAME, EMAIL1, LAST_LOGIN }) => {
            infoContext.setInfo(info => ({ ...info, firstName: FIRST_NAME, lastName: LAST_NAME, email: EMAIL1, lastLogin: LAST_LOGIN }));
            setFirstName(FIRST_NAME);
            setLastName(LAST_NAME);
            setEmail(EMAIL1);
            setIsLoading(false);
        }).catch(e => console.log(e));
    }

    return (

        <div className="page white-page">
            <div style={{ display: "flex" }}>
                <Menu />
                <div className="account-content">
                    <div className="userinfo">
                        <div className="account-header">
                            <h3 className="page-title account-title"> Account Info <button onClick={handleSave} disabled={saveDisabled} style={{ position: "absolute", right: "12px", top: "5px", border: `2px solid ${saveDisabled ? "#FAB57F" : "#e0e0e0"}`, backgroundColor: saveDisabled ? "#FAB57F" : "white"/*var(--bs-primary)"*/, borderRadius: 10, padding: "5px 10px" }}>{/*<span style={{fontFamily:"Trim-SemiBold", verticalAlign:"middle", fontSize:"18px", color:"var(--bs-primary)", paddingLeft:"4px", paddingRight:"18px"}}>Save</span>*/}<SaveIcon sx={{ color: "var(--bs-primary)"/*saveDisabled ?"#FAB57F":"white"*/, position: "relative", top: -1 }} /></button></h3>
                        </div>
                        {isLoading ?
                            <div className="account-spinner-container" >
                                <Spinner className="account-spinner" style={{ width: 70, height: 70 }} animation="border" variant="warning" />
                            </div>
                            : <div className="user-info-content">
                                <div style={{ display: "flex", flexWrap: "wrap", gap: "8px" }}>
                                    <TextInput value={firstName} invalid={invalidFirstName} light id="f-name" labelText="First Name" onChange={e => { setFirstName(e.target.value); setInvalidFirstName(false); setSaveDisabled(false) }} style={{ fontFamily: "Trim-Regular" }} ></TextInput>
                                    <TextInput value={lastName} invalid={invalidLastName} light id="l-name" labelText="Last Name" onChange={e => { setLastName(e.target.value); setInvalidLastName(false); setSaveDisabled(false) }} style={{ fontFamily: "Trim-Regular" }} ></TextInput>
                                </div>
                                <div style={{ display: "flex", marginBottom: 24 }}>
                                    <TextInput value={email} invalid={invalidEmail} light id="email" labelText="Email" onChange={e => { setEmail(e.target.value); setInvalidEmail(false); setSaveDisabled(false) }}></TextInput>
                                </div>
                            </div>}
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Account;